import React from 'react'
import './TopBar.scss'
function TopBar({config}) {
    return (
        <div className="TopBar">
            <nav>
                <ul className="menu">
                    <li  style={{ textAlign: "left" }} ><img style={{ width: 20, height: 20 }} src="https://portal.datagro.com/favicon.ico" /></li>
                    <li>
                        <a href="#">Sobre a DATAGRO</a>
                        <ul ><li><a href="https://portal.datagro.com/pt/about/company"> Empresa </a>
                        </li>
                            <li>
                                <a href="https://portal.datagro.com/pt/about/team"> Equipe </a>
                            </li>
                            <li>
                                 <hr className="hrr" />  
                            </li>
                            <li>
                                <a href="https://portal.datagro.com/pt/about/privacy"> Política de Privacidade </a>
                            </li>
                            <li>
                                <a href="https://portal.datagro.com/pt/about/terms"> Termos de Uso </a>
                            </li>
                        </ul>
                    </li>
                   
                    <li>
                        <a href={config.topBar.metodologia}>Metodologia do Indicador</a>
                    </li> 
                   
                    <li>
                        <a href="#">Ecossistema</a>
                        <ul>
                            <li>
                                <a href="http://benriratings.com.br" target="_blank" > Benri </a>
                            </li>
                            <li>
                                <a href="http://datamaps.com.br" target="_blank"> Datamaps </a>
                            </li>
                            <li>
                                <a href="http://deag.com.br" target="_blank"> Deag </a>
                            </li>
                            <li>
                                <a href="http://firealert.com.br" target="_blank" > FireAlert </a>
                            </li>
                            <li>
                                <a href="http://globalagribusinessforum.com" target="_blank" > GAF </a>
                            </li>
                            <li>
                                <a href="http://geoflorestas.com.br" target="_blank" > Geoflorestas </a>
                            </li>
                            <li>
                                <a href="http://hifarm.com.br" target="_blank" > Hifarm </a>
                            </li>
                            <li>
                                <a href="http://plantproject.com.br" target="_blank"> Plant Project </a>
                            </li>
                            <li>
                                <a href="http://geoflorestas.com.br/plataforma-renovar/" target="_blank" > Renovar </a>
                            </li>
                            <li>
                                <a href="http://startagro.agr.br" target="_blank" > Startagro </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="https://portal.datagro.com/pt/contact"> Contato </a></li>
                </ul>

            </nav>


        </div>
    )
}

export default TopBar
